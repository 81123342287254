import React from 'react'
import { usePageThemeContext } from 'src/lib/pageThemeContext'

const DesktopStartIcon = () => {
  const { accentColor } = usePageThemeContext()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      fill="none"
      viewBox="0 0 88 88"
    >
      <path
        fill="#EDEFEF"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.898"
        d="M54.79 69.22H34.144l4.301-19.572H50.49L54.79 69.22z"
      ></path>
      <path
        fill="#fff"
        d="M69.631 58.251h-50.33A4.314 4.314 0 0115 53.95V22.763a4.314 4.314 0 014.302-4.302h50.33a4.314 4.314 0 014.3 4.302V53.95a4.314 4.314 0 01-4.3 4.301z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.898"
        d="M69.631 58.251h-50.33A4.314 4.314 0 0115 53.95V22.763a4.314 4.314 0 014.302-4.302h50.33a4.314 4.314 0 014.3 4.302V53.95a4.314 4.314 0 01-4.3 4.301z"
      ></path>
      <path
        fill="#EDEFEF"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.898"
        d="M65.868 65.672H23.066v3.764h42.802v-3.764z"
      ></path>
      <path
        fill={accentColor}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M56.587 46.64l-21.116 2.728c-2.13.278-4.079-1.243-4.354-3.376l-1.572-12.36c-.275-2.132 1.247-4.085 3.377-4.363l21.104-2.7c2.13-.277 4.05 1.232 4.326 3.364l1.572 12.36c.287 2.105-1.206 4.07-3.337 4.347z"
      ></path>
      <path stroke="#fff" d="M39.45 37.942l4.437 3.258 5.75-7.828"></path>
    </svg>
  )
}

export default DesktopStartIcon
