import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { Box } from '@chatterbug/aaron'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import PageContainer from 'src/components/PageContainer/PageContainer'
import GiftsHero from 'src/sections/GiftsHero/GiftsHero'
import GiftSteps from 'src/components/GiftSteps/GiftSteps'
import WhyChatterbug from 'src/sections/WhyChatterbug/WhyChatterbug'
import GiftVouchers from 'src/sections/GiftVouchers/GiftVouchers'
import { useLocale } from 'src/lib/I18nContext'
import {
  NavigationItemName,
  GiftsNavigationItemName,
  PageContext,
  Product,
} from 'src/types'
import { GIFTS_THEME } from 'src/data/vouchers/gifts'
import { useLessonsGiftSteps } from 'src/components/GiftSteps/useLessonsGiftSteps'

const VouchersPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  const locale = useLocale()
  const giftSteps = useLessonsGiftSteps()

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        title={t('Vouchers & Gifts')}
        description={t('gifts_hero.sub_header')}
        socialImage={`/assets/global/og-images/gifts/lessons/${locale}.jpg`}
        pageContext={pageContext}
      />
      <GiftsHero
        variant={GIFTS_THEME}
        canonicalPath={pageContext.canonicalPath}
      />
      <Box as="section" id={GiftsNavigationItemName.howItWorks} mt="1x">
        <PageContainer>
          <GiftSteps bgColor="gray99" giftSteps={giftSteps} />
        </PageContainer>
      </Box>
      <Box as="section" id={NavigationItemName.whyChatterbug} mt={72}>
        <WhyChatterbug />
      </Box>
      <Box mt={120}>
        <GiftVouchers product={Product.lessons} />
      </Box>
    </Layout>
  )
}

export default VouchersPage
