import { useTranslate } from 'react-polyglot'
import { GiftStepsProps } from './GiftSteps'
import ChooseIcon from './subcomponents/GiftStep/assets/ChooseIcon'
import DesktopStartIcon from './subcomponents/GiftStep/assets/DesktopStartIcon'
import ShareIcon from './subcomponents/GiftStep/assets/ShareIcon'

export const useLessonsGiftSteps = () => {
  const t = useTranslate()

  const giftSteps: GiftStepsProps['giftSteps'] = [
    {
      type: 'choose',
      title: t('gift_step_lessons.choose.title'),
      description: t('gift_step_lessons.choose.description'),
      icon: ChooseIcon,
    },
    {
      type: 'share',
      title: t('gift_step_lessons.share.title'),
      description: t('gift_step_lessons.share.description'),
      icon: ShareIcon,
    },
    {
      type: 'start',
      title: t('gift_step_lessons.start.title'),
      description: t('gift_step_lessons.start.description'),
      icon: DesktopStartIcon,
    },
  ]

  return giftSteps
}
